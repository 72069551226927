import React, { useState } from 'react';

function AddTransaction({ addTransaction }) {
  const [desc, setDesc] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addTransaction({ desc, amount: parseFloat(amount) });
    setDesc('');
    setAmount('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Descrição</label>
        <input
          type="text"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Valor</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </div>
      <button type="submit">Adicionar Transação</button>
    </form>
  );
}

export default AddTransaction;