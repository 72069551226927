import React from 'react';

function TransactionList({ transactions }) {
  return (
    <ul>
      {transactions.map((transaction, index) => (
        <li key={index}>
          {transaction.desc}: {transaction.amount}
        </li>
      ))}
    </ul>
  );
}

export default TransactionList;
